<template>
  <footer class="footer bg-secondary">
    <div class="shape shape-top shape-curve-side bg-body">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 3000 250"
      >
        <path
          fill="currentColor"
          d="M3000,250V0H0v51c572.7-34.3,1125.3-34.3,1657.8,0C2190.3,85.2,2637.7,151.6,3000,250z"
        />
      </svg>
    </div>
    <div class="container align-items justify-around">
      <div>
        <img
          :src="src"
          alt="DEVMOOD logo"
          class="logo big"
        >

        <br>

        <h2 class="subtitle is-3 has-text-muted ml-4">{{ traductions.title }}</h2>
      </div>
      <div class="buttons">
        <b-button
          v-for="social in socials"
          :key="social.key"
          icon-pack="fa"
          type="is-ghost"
          size="is-large"
          :icon-left="social.icon"
          tag="a"
          :href="social.path"
        />
      </div>
    </div>
  </footer>
</template>

<script>
const devmoodLogoText = require('@portfolio/modules/assets/icons/DEVMOOD_TEXT_SVG.svg');

export default {
  name: 'Footer',
  props: {
    traductions: { type: Object, required: true },
  },
  data() {
    return {
      src: devmoodLogoText,
    };
  },
  computed: {
    socials() {
      return [
        { icon: 'linkedin', key: 'linkedin', path: 'https://www.linkedin.com/in/nesseirm/' },
        { icon: 'github', key: 'github', path: 'https://github.com/JunsyG92' },
        { icon: 'envelope', key: 'email', path: 'mailto:contact@devmood.fr' },
      ];
    },
  },
};
</script>
