import { use, registerComponent } from '../../$utils/plugins';

// Components
import DmButton from './Components/DmButton';
import DmSwitch from './Components/DmSwitch';

import Header from './Header';
import Footer from './Footer';

// Icons
import DevmoodIconLogoTextHorizontal from './Icons/DevmoodIconLogoTextHorizontal';
import DevmoodIconText from './Icons/DevmoodIconText';

const Plugin = {
  install(Vue) {
    // Component
    registerComponent(Vue, DmButton);
    registerComponent(Vue, DmSwitch);

    registerComponent(Vue, Header);
    registerComponent(Vue, Footer);

    // Icons
    registerComponent(Vue, DevmoodIconLogoTextHorizontal);
    registerComponent(Vue, DevmoodIconText);
  },
};

use(Plugin);

export default Plugin;

export {
  // Components
  DmButton,
  DmSwitch,

  Header,
  Footer,

  // Icons
  DevmoodIconLogoTextHorizontal,
  DevmoodIconText,
};
