<template>
  <section>
    <div class="multibar">
      <div class="end">
        <b-button
          type="is-tertiary"
          icon-pack="fa"
          :icon-left="dark ? 'sun' : 'moon'"
          :class="[{
            'text-color-4': dark,
            'text-color-9': !dark,
          }]"
        />
        <b-dropdown
          aria-role="list"
          position="is-bottom-left"
        >
          <template #trigger>
            <b-button
              :label="languages[language].flag"
              type="is-ghost"
            />
          </template>

          <b-dropdown-item
            v-for="lang in languages"
            :key="lang.key"
            :active="lang.key === language"
            aria-role="listitem"
            @click="$emit('language', lang.key)"
          >
            {{ lang.flag }} {{ lang.name }}
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
    <div class="maintenance">
      <div class="content">
        <img
          :src="src"
          alt="DEVMOOD logo"
          class="logo big"
        >

        <h1 class="mt-4 text-center m-0 p-0">
          <span class="lead has-text-muted">{{ traductions.title }}</span>
        </h1>
      </div>
    </div>
  </section>
</template>

<script>
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable-next-line */
import TheLanguagesMixins from '@portfolio/addons/home/$mixins/TheLanguages.mixins'
const devmoodLogoText = require('@portfolio/modules/assets/icons/DEVMOOD_TEXT_SVG.svg');

export default {
  name: 'Maintenance',
  mixins: [TheLanguagesMixins],
  props: {
    traductions: { type: Object, required: true },
    language: { type: String, required: true },
  },
  data() {
    return {
      src: devmoodLogoText,
      dark: true,
    };
  },
};
</script>
