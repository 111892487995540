<template>
  <header>
    <b-navbar
      fixed-top
      shadow
      spaced
    >
      <template #brand>
        <b-navbar-item
          tag="router-link"
          :to="{ path: '/'}"
        >
          <img
            :src="src"
            alt="DEVMOOD logo"
            class="logo small"
          >
        </b-navbar-item>
      </template>
      <!-- <template #start>
        <template v-for="nav in traductions.navigations">
          <b-navbar-item
            v-if="nav.visible"
            :key="nav.key"
            :active="nav.active"
          >
            {{ nav.name }}
          </b-navbar-item>
        </template>
      </template> -->
      <template #end>
        <b-navbar-dropdown
          :label="languages[language].flag"
          right
        >
          <b-navbar-item
            v-for="lang in languages"
            :key="lang.key"
            :active="lang.key === language"
            @click="$emit('language', lang.key)"
          >
            {{ lang.flag }} {{ lang.name }}
          </b-navbar-item>
        </b-navbar-dropdown>
      </template>
    </b-navbar>
  </header>
</template>

<script>
/* eslint-disable-next-line */
import TheLanguagesMixins from '@portfolio/addons/home/$mixins/TheLanguages.mixins'
const devmoodLogoText = require('@portfolio/modules/assets/icons/DEVMOOD_TEXT_SVG.svg');

export default {
  name: 'Header',
  mixins: [TheLanguagesMixins],
  props: {
    language: { type: String, required: true },
    traductions: { type: Object, required: true },
  },
  data() {
    return {
      src: devmoodLogoText,
    };
  },
};
</script>
