/**
 * @todo Refaire ces fonctions pour utiliser query.find, et ne pas être dépendant du populate qu'on fait dans TheInbox.store
 */

export function threadHasContactGroup(thread, contactGroup) {
  const _cg = thread
    .contactGroups
    .find((cg) => cg.contactGroupId === contactGroup.contactGroupId);
  return !!_cg;
}

export function threadHasThreadGroup(thread, threadGroup) {
  const _tg = thread
    .threadGroups
    .find((tg) => tg.threadGroupId === threadGroup.threadGroupId);
  return !!_tg;
}

export default {
  threadHasContactGroup,
  threadHasThreadGroup,
};
