<template>
  <div id="TheHome">
    <Maintenance
      v-if="isInMaintenance"
      :traductions="maintenance[language]"
      :language="language"
      @language="updateLanguage($event)"
    />
    <template v-else>
      <Header
        :traductions="header[language]"
        :language="language"
        @language="updateLanguage($event)"
      />

      <Intro
        :traductions="introduction[language]"
        :language="language"
      />

      <section class="container align-center justify-center">
        <div class="cardParcours">
          <div class="container align-center justify-around">
            <h3 class="">
              <span class="subtitle is-3 has-text-muted">{{ about[language].title }}</span>
              <br>
              <span class="subtitle is-2">{{ about[language].subtitle }}</span>
            </h3>
            <p>{{ about[language].content }}</p>
          </div>
        </div>
      </section>

      <br>

      <Skills
        :traductions="services[language]"
        :language="language"
      />

      <br>

      <Prices
        :traductions="tarifs[language]"
        :language="language"
      />
    </template>
  </div>
</template>

<script>
/* eslint-disable-next-line */
import { mapActions, mapGetters } from 'vuex';
import TraductionsMixins from './$mixins/TheHomeContentTraduction.mixins';

export default {
  name: 'TheHome',
  mixins: [TraductionsMixins],
  data() {
    return {
      loading: false,
      isInMaintenance: true,
    };
  },
  computed: {
    ...mapGetters({
      language: 'TheHome/getterCurrentLanguage',
    }),
  },
  methods: {
    ...mapActions({
      updateLanguage: 'TheHome/updateLanguage',
    }),
  },
};
</script>
