/* eslint-disable max-len */
export default {
  namespaced: true,
  state: {
    navigation: {
      menuItems: [
        {
          path: '/',
          text: 'What\'s New',
        },
      ],
    },
    language: 'fr',
  },
  getters: {
    navigation: (state) => state.navigation,
    getterCurrentLanguage: (state) => state.language,
  },
  mutations: {
    UPDATE_LANGUAGE(state, newData) { state.language = newData; },
  },
  actions: {
    updateLanguage({ commit }, lang) {
      commit('UPDATE_LANGUAGE', lang);
    },
  },
};
