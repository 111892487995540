<template>
  <a
    v-if="isLink"
    :href="url"
    :class="['button', {
      'is-primary': type === 'primary',
      'is-secondary': type === 'secondary',
      'is-ghost': type === 'ghost',

    }]"
    @click="$emit('click')"
  >
    {{ label }}
  </a>
  <button
    v-else
    :class="['button', {
      'is-primary': type === 'primary',
      'is-secondary': type === 'secondary',
      'is-ghost': type === 'ghost',
    }]"
    @click="$emit('click')"
  >
    {{ label }}
  </button>
</template>

<script>
export default {
  name: 'DmButton',
  props: {
    isLink: { type: Boolean, default: false },
    label: { type: String, default: '' },
    url: { type: String, default: '' },
    type: { type: String, default: 'primary' },
  },
};
</script>
