import Vue from 'vue';
import Buefy from 'buefy';

Vue.use(Buefy);

Vue.directive('focus', { inserted(el) { setTimeout(() => el.focus(), 100); } });

Vue.filter('capitalize', (value) => (value ? value.toString().charAt(0).toUpperCase() + value.slice(1) : ''));

Vue.filter('lowercase', (value) => (value ? value.toString().toLowerCase() : ''));
