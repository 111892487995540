import moment from 'moment';

// 1er décembre 2000
const beginMicro = 946684800000 * 1000;
const beginMilli = 946684800000;
const beginSec = 946684800;

const minute = 60;
const hour = minute * 60;
const day = hour * 24;
const week = day * 7;
const month = day * 30.44;
const year = day * 365;

const stampToDatetime = (stamp) => {
  const numStamp = Number(stamp);
  if (numStamp > beginMilli && numStamp > beginMicro) {
    return moment(Math.floor(numStamp / 1000));
  }
  if (numStamp > beginSec && numStamp < beginMilli) {
    return moment.unix(numStamp);
  }
  return moment(numStamp);
};

const secondsToNow = (stamp) => {
  const datetime = stampToDatetime(stamp);
  const now = moment();
  const diff = now.diff(datetime);
  return moment.duration(diff).as('seconds');
};

const timeToNow = (stamp) => {
  const seconds = secondsToNow(stamp);
  // console.log('🚀 ~ file: dates.js ~ line 32 ~ timeToNow ~ seconds', seconds);
  // const date = stampToDatetime(stamp);
  // console.log('🚀 ~ file: dates.js ~ line 33 ~ timeToNow ~ date', date);
  if (seconds > year) {
    // console.log('🚀 ~ file: dates.js ~ line 36 ~ timeToNow ~ seconds > year');
    // return `${Math.floor(seconds / year)}y`;
    // return date.format('YYYY-MM-DD');
    return `${Math.floor(seconds / day)}d`;
  }
  if (seconds > month) {
    // console.log('🚀 ~ file: dates.js ~ line 36 ~ timeToNow ~ seconds > month');
    // return `${Math.floor(seconds / month)}mon`;
    // return date.format('YYYY-MM-DD');
    return `${Math.floor(seconds / day)}d`;
  }
  if (seconds > week) {
    // console.log('🚀 ~ file: dates.js ~ line 36 ~ timeToNow ~ seconds > week');
    return `${Math.floor(seconds / day)}d`;
    // return date.format('YYYY-MM-DD');
  }
  if (seconds > day) {
    // console.log('🚀 ~ file: dates.js ~ line 36 ~ timeToNow ~ seconds > day');
    return `${Math.floor(seconds / day)}d`;
  }
  if (seconds > hour) {
    // console.log('🚀 ~ file: dates.js ~ line 36 ~ timeToNow ~ seconds > hour');
    // console.log('🚀 ~ file: dates.js ~ line 60 ~ timeToNow ~ seconds', seconds);
    return `${Math.floor(seconds / hour)}h`;
  }
  if (seconds > minute) {
    // console.log('🚀 ~ file: dates.js ~ line 36 ~ timeToNow ~ seconds < hour');
    // console.log('🚀 ~ file: dates.js ~ line 65 ~ timeToNow ~ seconds', seconds);
    return `${Math.floor(seconds / minute)}m`;
  }
  return 'now';
};

export {
  stampToDatetime,
  secondsToNow,
  timeToNow,
};
