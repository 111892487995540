<template>
  <label class="switch is-rounded">
    <input type="checkbox">
    <span class="check" />
    <span
      v-if="label"
      class="control-label"
    >
      {{ label }}
    </span>
  </label>
</template>

<script>
export default {
  name: 'DmSwitch',
  props: {
    label: { type: String, default: '' },
  },
  computed: {
  },
};
</script>
