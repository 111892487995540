import Vue from 'vue';
import VueRouter from 'vue-router';
// import { SnackbarProgrammatic as Snackbar } from 'buefy';
import { routes as addonsRoutes } from './addons';
// import store from './store';

Vue.use(VueRouter);

const routes = [
  // {
  //   path: '/',
  //   redirect: '/',
  // },
];
console.log(Object.values(addonsRoutes));
Object.values(addonsRoutes).forEach((addonRoutes) => {
  routes.push(...addonRoutes);
});

routes.push({ path: '*', redirect: '/error/404' });
const router = new VueRouter({
  routes,
  mode: 'history',
});

// router.beforeEach((to, from, next) => {
//   if (to.matched.some((record) => record.meta.requireAuth === true)) {
//     const auth = store.getters['Authenticate/getterAuthenticate'];
//     // console.log('🚀 ~ file: router.js ~ line 9 ~ auth', auth);
//     // console.log('Going to', to, 'from', from, 'require auth', auth);
//     if (process.env.VUE_APP_MODE === 'dev') {
//       return next();
//     }
//     if (!auth) {
//       return Promise.resolve()
//         .then(() => Snackbar.open({
//           message: 'You must be connected to access.',
//           type: 'is-danger',
//           position: 'is-top',
//           duration: 5000000,
//         }))
//         .then(() => next({ name: 'Login' }));
//     }
//     return next();
//   }
//   return next();
// });

export default router;
