<template>
  <img
    :src="logo"
    alt="Logo DEVMOOD textuel"
  >
</template>

<script>
/* eslint-disable-next-line */
const DevmoodIconText = require('@portfolio/modules/assets/icons/DEVMOOD_TEXT_SVG.svg');

export default {
  name: 'DevmoodIconText',
  data() {
    return {
      logo: DevmoodIconText,
    };
  },
};
</script>
