<template>
  <section class="prices">
    <div class="container justify-center align-center">
      <h3 class="text-center">
        <span class="subtitle is-3 has-text-muted">{{ traductions.titleOne }}</span>
        <br>
        <span class="subtitle is-2">{{ traductions.titleTwo }}</span>
      </h3>
    </div>

    <br>

    <div class="container justify-around">
      <article
        v-for="card in traductions.cards"
        :key="card.title"
        :class="['card', {
          'top': card.primary
        }]"
      >
        <div class="card-header">
          <h4 class="card-title subtitle is-2 has-text-muted">
            {{ card.title }}
          </h4>
        </div>
        <div class="card-content">
          <div class="price">
            <p
              v-if="card.price"
              class="container"
            >
              <span class="has-text-light-primary">{{ card.price }} €</span>
              <span class="has-text-light small wordByWord">
                {{ card.priceText }}
              </span>
            </p>  
            <p
              v-if="card.text"
              class="has-text-light"
            >
              {{ card.text }}
            </p>
          </div>

          <br>

          <ul>
            <li
              v-for="el in card.contents"
              :key="el"
              class="container element"
            >
              <b-icon
                class="has-text-primary"
                pack="fa"
                icon="check"
              />
              <span class="text is-3">{{ el }}</span>
            </li>
          </ul>
        </div>
      </article>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Prices',
  props: {
    language: { type: String, required: true },
    traductions: { type: Object, required: true },
  },
};
</script>
