export default {
  methods: {
    convertColor(hex) {
      let color;
      switch (hex) {
        case '#4D4D4D': color = '1'; break;
        case '#FF6060': color = '2'; break;
        case '#FFA471': color = '3'; break;
        case '#FFC670': color = '4'; break;
        case '#6BCE3D': color = '5'; break;
        case '#73DA8A': color = '6'; break;
        case '#43D2C1': color = '7'; break;
        case '#3CA6F2': color = '8'; break;
        case '#6F6DE3': color = '9'; break;
        case '#B98DF0': color = '10'; break;
        case '#F882EC': color = '11'; break;
        case '#FF62A4': color = '12'; break;
        default: color = '1'; break;
      }
      return color;
    },
    convertFromColor(hex) {
      let color;
      switch (Number(hex)) {
        case 1: color = '#4D4D4D'; break;
        case 2: color = '#FF6060'; break;
        case 3: color = '#FFA471'; break;
        case 4: color = '#FFC670'; break;
        case 5: color = '#6BCE3D'; break;
        case 6: color = '#73DA8A'; break;
        case 7: color = '#43D2C1'; break;
        case 8: color = '#3CA6F2'; break;
        case 9: color = '#6F6DE3'; break;
        case 10: color = '#B98DF0'; break;
        case 11: color = '#F882EC'; break;
        case 12: color = '#FF62A4'; break;
        default: color = '#4D4D4D'; break;
      }
      return color;
    },

    humanizeInteger(nb) {
      // if 0 < nb < 1 round 2 number decimal
      if (nb > 0 && nb < 1) return `${Math.round(nb * 100) / 100}`;

      if (nb < 1000) {
        return `${Math.floor(nb)}`;
      }
      if (nb < 10000) {
        return `${(nb / 1000).toFixed(1)}K`;
      }
      if (nb < 1000000) {
        return `${Math.floor(nb / 1000)}K`;
      }
      if (nb < 10000000) {
        return `${(nb / 1000000).toFixed(1)}M`;
      }
      return `${Math.floor(nb / 1000000)}M`;
    },

    humanizeFloat(float, hasPercent = false) {
      if (!float) return float;
      return hasPercent ? `${parseFloat(float.toFixed(3))} %` : parseFloat(float.toFixed(3));
    },
  },
};
