<template>
  <div class="buttons">
    <b-button
      :label="buttons[language].linkedin"
      icon-pack="fa"
      icon-left="linkedin"
    />
    <b-button
      :label="buttons[language].cv"
      icon-pack="fa"
      icon-left="download"
    />
  </div>
</template>

<script>
/* eslint-disable-next-line */
import TraductionsMixins from '../$mixins/TheHomeContentTraduction.mixins';

export default {
  name: 'ButtonActionsContact',
  mixins: [TraductionsMixins],
  props: {
    language: { type: String, required: true },
  },
};
</script>
