import { use, registerComponent } from '../../$utils/plugins';

import Intro from './Intro';
import Skills from './Skills';
import Prices from './Prices';
import Maintenance from './Maintenance';

import Linkedin from './Buttons/Linkedin';
import Cv from './Buttons/Cv';

const Plugin = {
  install(Vue) {
    registerComponent(Vue, Intro);
    registerComponent(Vue, Skills);
    registerComponent(Vue, Prices);
    registerComponent(Vue, Maintenance);

    registerComponent(Vue, Linkedin);
    registerComponent(Vue, Cv);
  },
};

use(Plugin);

export default Plugin;

export {
  Intro,
  Skills,
  Prices,
  Maintenance,

  Linkedin,
  Cv,
};
