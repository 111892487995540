export default {
  computed: {
    introduction() {
      return {
        fr: {
          title: 'Salut ! Je m\'appelle Mathieu.',
          subtitle: 'Développeur JavaScript Full stack.',
        },
        en: {
          title: 'Hi! My name is Mathieu.',
          subtitle: 'Full stack JavaScript developper.',
        },
      };
    },
    about() {
      return {
        fr: {
          title: 'À propos',
          subtitle: 'de mon parcours',
          content: '',
        },
        en: {
          title: 'About',
          subtitle: 'my career',
          content: '',
        },
      };
    },
    services() {
      return {
        fr: {
          titleOne: 'Voyons ensemble comment lier',
          titleTwo: 'mes connaissances à vos besoins.',
          skills: 'Technologies et outils:',
          frontend: {
            title: 'La partie visible de l\'iceberg',
            action: 'J\'ai besoin d\'un dev front-end !',
            contents: [
              'Mise en place d\'une application Vue.js',
              'Intégration d\'une interface graphique depuis une maquette',
              'Création de components réutilisable et maintenable',
              'Connecter une ou plusieurs APIs',
              'Optimisations, performances et rapidité',
              'Optimisation des stores avec Vuex',
            ],
            technos: [
              'JavaScript', 'Vue.js', 'Nuxt.js', 'Vuex', 'Vue Router', 'Buefy', 'Scss', 'Bootstrap', 'HTML5',
            ],
          },
          backend: {
            title: 'La partie cachée de l\'iceberg',
            action: 'J\'ai besoin d\'un dev back-end !',
            contents: [
              'Création d\'APIs',
              'Mise en place d\'une base de donnée SQL ou NoSQL',
              'Mise en place d\'endpoints',
              'Protection des endpoints avec la mise en place de règles avancé',
              'Tests unitaire',
            ],
            technos: [
              'JavaScript', 'SQL', 'Node.js', 'Express.js', 'Objection.js', 'Knex.js', 'Mocha', 'Joi', 'PostgreSQL', 'MySQL', 'MongoDB', 'Postman',
            ],
          },
          utils: {
            title: 'Facilitons-nous la vie !',
            action: 'Connectons-nous à la simplicité !',
            contents: [
              'Connaissances de certains outils No-code / Low-code',
              'Connected les APIs des outils entre eux',
              'SEO',
            ],
            technos: [
              'Wordpress', 'Airtable', 'Zapier', 'Integromat', 'Notion', 'Bootstrap Studio', 'Services Google', 'Figma', 'Webflow',
            ],
          },
          works: {
            title: 'Travailler dur c\'est bien, travailler intelligemment c\'est mieux !',
            action: 'Je te veux dans mon équipe!',
            contents: [
              'Connaissances des outils de gestion de projets',
              'Méthode agile',
              'Communications',
              'Adaptation',
            ],
            technos: [
              'Github', 'Gitlab', 'Notion', 'Trello', 'Monday', 'Slack', 'Zoom', 'Google meet', 'Discord',
            ],
          },
        },
        en: {
          titleOne: 'Let\'s see how to link',
          titleTwo: 'my knowledge to your needs.',
          skills: 'Technologies and tools:',
          frontend: {
            title: 'The tip of the iceberg',
            action: 'I need a front-end developer!',
            contents: [
              'Implementation of a Vue.js application',
              'Integration of a graphical interface from a mockup',
              'Creation of reusable and maintainable components',
              'Connecting one or more APIs',
              'Optimizations, performances and speed',
              'Optimization of blinds with Vuex',
            ],
            technos: [
              'JavaScript', 'Vue.js', 'Nuxt.js', 'Vuex', 'Vue Router', 'Buefy', 'Scss', 'Bootstrap', 'HTML5', '...',
            ],
          },
          backend: {
            title: 'The hidden part of the iceberg',
            action: 'I need a back-end developer!',
            contents: [
              'Creation of APIs',
              'Setting up a SQL or NoSQL database',
              'Setting up endpoints',
              'Protection of endpoints with the implementation of advanced rules',
              'Unit testing',
            ],
            technos: [
              'JavaScript', 'SQL', 'Node.js', 'Express.js', 'Objection.js', 'Knex.js', 'Mocha', 'Joi', 'PostgreSQL', 'MySQL', 'MongoDB', 'Postman',
            ],
          },
          utils: {
            title: 'Let\'s make life easier!',
            action: 'Let\'s connect to simplicity!',
            contents: [
              'Knowledge of some No-code / Low-code tools',
              'Connected the APIs of the tools between them',
              'SEO',
            ],
            technos: [
              'Wordpress', 'Airtable', 'Zapier', 'Integromat', 'Notion', 'Bootstrap Studio', 'Services Google', 'Figma', 'Webflow',
            ],
          },
          works: {
            title: 'Working hard is good, working smart is better!',
            action: 'I want you on my team!',
            contents: [
              'Knowledge of project management tools',
              'Agile method',
              'Communications',
              'Adaptation',
            ],
            technos: [
              'Github', 'Gitlab', 'Notion', 'Trello', 'Monday', 'Slack', 'Zoom', 'Google meet', 'Discord',
            ],
          },
        },
      };
    },
    experiences() {
      return {
        fr: {},
        en: {},
      };
    },
    recommandations() {
      return {
        fr: {
          titleOne: 'J\'ai travaillé avec eux,',
          titleTwo: 'ils me recommandent.',
          noContent: 'Ça arrive très bientôt !',
        },
        en: {
          titleOne: 'I have worked with them,',
          titleTwo: 'they recommend me.',
          noContent: 'It\'s coming soon!',
        },
      };
    },
    tarifs() {
      return {
        fr: {
          titleOne: 'Mon profil vous intéresse ?',
          titleTwo: 'Voici mes tarifs.',
          cards: [
            {
              title: 'Freelance',
              price: 450,
              priceText: 'par jour',
              primary: true,
              contents: [
                'Missions courte ou longue durée',
                'Conception d\'une interface utilisateur',
                'Création d\'API',
                'Gestion de base de donnée',
                'Création et optimisations de composants',
                'Ajout de nouvelles fonctionnalitées',
                'Intégration web',
                'Résolution de bugs',
                'Mise en place de tests unitaire',
                'Mise en place de règles de sécurité',
                'Autre...',
              ],
            },
            {
              title: 'Une idée ?',
              text: 'Devis personnalisé.',
              primary: false,
              contents: [
                'Logiciel Saas',
                'Site e-commerce',
                'Landing page',
                'Autre...',
              ],
            },
          ],
        },
        en: {
          titleOne: 'Are you interested?',
          titleTwo: 'Here are my prices.',
          cards: [
            {
              title: 'Freelance',
              price: 450,
              priceText: 'per day',
              primary: true,
              contents: [
                'Short or long term missions',
                'Design of a user interface',
                'API creation',
                'Database management',
                'Creation and optimization of components',
                'Addition of new functionalities',
                'Web integration',
                'Bug fixing',
                'Unit test implementation',
                'Implementation of security rules',
                'Other...',
              ],
            },
            {
              title: 'An idea?',
              text: 'Devis personnalisé.',
              primary: false,
              contents: [
                'Saas software',
                'E-commerce website',
                'Landing page',
                'Other...',
              ],
            },
          ],
        },
      };
    },
    header() {
      return {
        fr: {
          navigations: [
            {
              name: 'Services', key: 'services', visible: true, active: true,
            },
            {
              name: 'Recommandations', key: 'recommandations', visible: true, active: true,
            },
            {
              name: 'Tarifs', key: 'tarifs', visible: true, active: true,
            },
          ],
        },
        en: {
          navigations: [
            {
              name: 'Services', key: 'services', visible: true, active: true,
            },
            {
              name: 'Recommandations', key: 'recommandations', visible: true, active: true,
            },
            {
              name: 'Prices', key: 'tarifs', visible: true, active: true,
            },
          ],
        },
      };
    },
    footer() {
      return {
        fr: {
          title: 'Développeur JavaScript Full stack.',
        },
        en: {
          title: 'Full stack JavaScript developper.',
        },
      };
    },
    buttons() {
      return {
        fr: {
          linkedin: 'Discutons ensemble',
          cv: 'Télécharger mon CV',
        },
        en: {
          linkedin: 'Let\'s talk together',
          cv: 'Download my CV',
        },
      };
    },
    maintenance() {
      return {
        fr: {
          title: 'Le site est en maintenance',
        },
        en: {
          title: 'The site is under maintenance',
        },
      };
    },
  },
};
