<template>
  <section class="services">
    <div class="container justify-center align-center">
      <h3 class="text-center">
        <span class="subtitle is-3 has-text-muted">{{ traductions.titleOne }}</span>
        <br>
        <span class="subtitle is-2">{{ traductions.titleTwo }}</span>
      </h3>
    </div>

    <br>

    <article
      v-for="({ key, reverse, img, alt }, index) in skills"
      :key="key"
      :class="['skill', {
        'no-border': index + 1 >= skills.length
      }]"
    >
      <div
        :class="['container justify-around align-center', {
          'flex-reverse': reverse
        }]"
      >
        <div class="content">
          <h4 class="text is-1">{{ traductions[key].title }}</h4>

          <ul class="listSkill">
            <li
              v-for="content in traductions[key].contents"
              :key="content"
            >
              <div class="container">
                <b-icon
                  class="has-text-success"
                  pack="fa"
                  icon="circle-check"
                />

                <span class="text">{{ content }}</span>
              </div>
            </li>
          </ul>

          <br>

          <h4 class="text is-1 m-0">{{ traductions.skills }}</h4>

          <br>

          <p>
            <strong
              v-for="(techno, i) in traductions[key].technos"
              :key="techno"
              class="text is-3"
            >
              <span class="subtext">{{ techno }}</span>
              <span v-if="i + 1 < traductions[key].technos.length">, </span>
            </strong>
          </p>

          <br>

          <Linkedin
            :label="traductions[key].action"
            type="is-ghost"
            position-icon="right"
            icon="chevron-right"
          />
        </div>
      </div>
    </article>
  </section>
</template>

<script>
/* eslint-disable import/no-extraneous-dependencies */
const frontend = require('@portfolio/modules/assets/images/frontend.svg');
const backend = require('@portfolio/modules/assets/images/backend.svg');
const works = require('@portfolio/modules/assets/images/works.svg');
const utils = require('@portfolio/modules/assets/images/utils.svg');

export default {
  name: 'Skills',
  props: {
    language: { type: String, required: true },
    traductions: { type: Object, required: true },
  },
  computed: {
    skills() {
      return [
        {
          key: 'frontend',
          alt: 'Visuel "frontend"',
          img: frontend,
          reverse: false,
        },
        {
          key: 'backend',
          alt: 'Visuel "backend"',
          img: backend,
          reverse: true,
        },
        {
          key: 'utils',
          alt: 'Visuel "utils"',
          img: utils,
          reverse: false,
        },
        {
          key: 'works',
          alt: 'Visuel "works"',
          img: works,
          reverse: true,
        },
      ];
    },
  },
};
</script>
