<template>
  <!-- eslint-disable max-len -->
  <svg
    id="Calque_1"
    :width="width"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 500 100.98"
    style="enable-background:new 0 0 500 100.98;"
    xml:space="preserve"
  >
    <g>
      <g>
        <g>
          <linearGradient
            id="SVGID_1_"
            gradientUnits="userSpaceOnUse"
            x1="102.6293"
            y1="50.4918"
            x2="184.0749"
            y2="50.4918"
          >
            <stop
              offset="0"
              style="stop-color:#D1BAD2"
            />
            <stop
              offset="1"
              style="stop-color:#A5A4CB"
            />
          </linearGradient>
          <path
            class="st0"
            d="M145.6,11.32c16.95,0,30.69,13.74,30.69,30.68s-13.16,30.2-30.11,30.2l-26.11,0V38.76h-9.17
                      c-1.31,0-2.55,0.31-3.65,0.86c-2.74,1.35-4.63,4.16-4.63,7.42v25.17v9.17c0,4.57,3.71,8.28,8.28,8.28l34.69-0.02
                      c21.31-0.38,38.47-17.76,38.47-39.16C184.07,29.08,166.91,11.7,145.6,11.32z"
          />
          <linearGradient
            id="SVGID_2_"
            gradientUnits="userSpaceOnUse"
            x1="102.6293"
            y1="41.777"
            x2="176.2933"
            y2="41.777"
          >
            <stop
              offset="0"
              style="stop-color:#F8C5D1"
            />
            <stop
              offset="1"
              style="stop-color:#9592C7"
            />
          </linearGradient>
          <path
            class="st1"
            d="M146.18,72.23c16.95,0,30.11-13.28,30.11-30.22s-13.75-30.67-30.69-30.67l-42.97-0.02v9.17
                      c0,4.57,3.71,8.28,8.28,8.28l31.8,0c1.07,0,2.13,0.09,3.18,0.29c10.35,2.01,20.87,10.84,20.87,21.44
                      c0,10.62-7.57,19.47-17.61,21.45C148.18,72.15,147.18,72.23,146.18,72.23z"
          />
        </g>
        <g>

          <linearGradient
            id="SVGID_3_"
            gradientUnits="userSpaceOnUse"
            x1="289.4513"
            y1="-69.1615"
            x2="370.8969"
            y2="-69.1615"
            gradientTransform="matrix(-1 0 0 -1 383.5198 -18.6697)"
          >
            <stop
              offset="0"
              style="stop-color:#D1BAD2"
            />
            <stop
              offset="1"
              style="stop-color:#A5A4CB"
            />
          </linearGradient>
          <path
            class="st2"
            d="M94.07,62.22V28.77v-9.17c0-4.57-3.71-8.28-8.28-8.28L51.1,11.34C29.79,11.72,12.62,29.1,12.62,50.5
                      c0,21.4,17.16,38.78,38.47,39.16c-21.19,0-37.38-21.48-27.94-43.85c3.98-9.43,15.87-17.04,26.1-17.04l27.37,0v24.91
                      c0,4.72,3.82,8.54,8.54,8.54H94.07z"
          />

          <linearGradient
            id="SVGID_4_"
            gradientUnits="userSpaceOnUse"
            x1="289.4513"
            y1="-77.8763"
            x2="363.1153"
            y2="-77.8763"
            gradientTransform="matrix(-1 0 0 -1 383.5198 -18.6697)"
          >
            <stop
              offset="0"
              style="stop-color:#F8C5D1"
            />
            <stop
              offset="1"
              style="stop-color:#9592C7"
            />
          </linearGradient>
          <path
            class="st3"
            d="M50.51,28.75c-18.04,0-30.11,13.28-30.11,30.22S34.15,89.64,51.1,89.64l42.97,0.02v-9.17
                      c0-4.57-3.71-8.28-8.28-8.28l-31.8,0c-1.07,0-2.13-0.09-3.18-0.29c-10.35-2.01-20.87-10.84-20.87-21.44
                      c0-10.62,7.57-19.47,17.61-21.45C48.52,28.84,49.52,28.75,50.51,28.75z"
          />
        </g>
      </g>
      <g>
        <path
          class="st4"
          d="M292.73,66.2h-22.7V34.78h21.92v2.3h-19.63V48.7h18.4v2.3h-18.4v12.91h20.4V66.2z"
        />
        <path
          class="st4"
          d="M329.47,34.78L317.16,66.2h-2.96l-12.31-31.42h2.55l11.25,28.55l11.25-28.55H329.47z"
        />
        <path
          class="st4"
          d="M369.11,66.2h-2.3V38.54L355.51,66.2h-2.41l-11.28-27.66V66.2h-2.3V34.78h3.33l11.45,28.55l11.54-28.55h3.27
                  V66.2z"
        />
        <path
          class="st4"
          d="M395.53,34.21c2.14,0,4.12,0.38,5.94,1.15c1.82,0.76,3.38,1.86,4.69,3.27s2.33,3.12,3.07,5.11
                  s1.1,4.21,1.1,6.66c0,2.43-0.36,4.65-1.09,6.66c-0.73,2.01-1.75,3.73-3.06,5.18c-1.31,1.45-2.87,2.56-4.68,3.36
                  c-1.81,0.79-3.8,1.19-5.98,1.19c-2.18,0-4.18-0.4-6-1.19c-1.82-0.79-3.38-1.91-4.69-3.34s-2.33-3.16-3.06-5.17
                  c-0.73-2.01-1.09-4.24-1.09-6.69c0-2.47,0.37-4.7,1.1-6.69c0.74-1.99,1.76-3.69,3.08-5.09c1.32-1.41,2.88-2.49,4.69-3.26
                  C391.38,34.59,393.36,34.21,395.53,34.21z M395.5,64.48c1.8,0,3.46-0.31,4.98-0.93c1.52-0.62,2.84-1.54,3.95-2.74
                  c1.11-1.21,1.97-2.68,2.58-4.43c0.61-1.75,0.92-3.75,0.92-5.98c0-2.26-0.31-4.25-0.93-5.97c-0.62-1.72-1.48-3.17-2.58-4.35
                  c-1.1-1.18-2.4-2.07-3.9-2.67s-3.14-0.9-4.92-0.9c-1.82,0-3.49,0.31-5.01,0.92c-1.52,0.61-2.84,1.51-3.95,2.68
                  c-1.11,1.18-1.98,2.63-2.6,4.35s-0.93,3.7-0.93,5.94s0.31,4.23,0.92,5.98c0.61,1.75,1.47,3.23,2.57,4.43
                  c1.1,1.21,2.41,2.12,3.93,2.74C392.04,64.17,393.7,64.48,395.5,64.48z"
        />
        <path
          class="st4"
          d="M435.42,34.21c2.14,0,4.12,0.38,5.94,1.15c1.82,0.76,3.38,1.86,4.69,3.27c1.31,1.42,2.33,3.12,3.07,5.11
                  c0.74,1.99,1.11,4.21,1.11,6.66c0,2.43-0.36,4.65-1.09,6.66c-0.73,2.01-1.75,3.73-3.06,5.18c-1.31,1.45-2.87,2.56-4.67,3.36
                  c-1.81,0.79-3.8,1.19-5.99,1.19c-2.18,0-4.18-0.4-6-1.19c-1.82-0.79-3.38-1.91-4.69-3.34c-1.31-1.43-2.33-3.16-3.06-5.17
                  s-1.09-4.24-1.09-6.69c0-2.47,0.37-4.7,1.1-6.69c0.74-1.99,1.76-3.69,3.08-5.09s2.88-2.49,4.69-3.26
                  C431.28,34.59,433.26,34.21,435.42,34.21z M435.4,64.48c1.8,0,3.46-0.31,4.98-0.93c1.52-0.62,2.83-1.54,3.94-2.74
                  c1.11-1.21,1.97-2.68,2.58-4.43c0.61-1.75,0.92-3.75,0.92-5.98c0-2.26-0.31-4.25-0.93-5.97c-0.62-1.72-1.48-3.17-2.58-4.35
                  c-1.1-1.18-2.4-2.07-3.9-2.67c-1.5-0.6-3.14-0.9-4.92-0.9c-1.82,0-3.48,0.31-5.01,0.92c-1.52,0.61-2.83,1.51-3.94,2.68
                  c-1.11,1.18-1.98,2.63-2.6,4.35c-0.62,1.72-0.93,3.7-0.93,5.94s0.31,4.23,0.92,5.98c0.61,1.75,1.47,3.23,2.57,4.43
                  c1.1,1.21,2.41,2.12,3.93,2.74C431.94,64.17,433.6,64.48,435.4,64.48z"
        />
        <path
          class="st4"
          d="M255.67,56.44V34.81h-9.5c-5.3,0-9.27,1.28-11.91,3.83c-2.64,2.55-3.96,6.42-3.96,11.61
                  c0,10.64,5.29,15.96,15.87,15.96h9.5v-2.3h-2.3h-7.15c-2.62,0-4.8-0.31-6.53-0.92c-1.73-0.61-3.11-1.51-4.15-2.68
                  c-1.03-1.18-1.76-2.61-2.18-4.29s-0.63-3.61-0.63-5.77c0-2.07,0.2-3.91,0.62-5.54c0.41-1.63,1.13-3.01,2.17-4.15
                  c1.03-1.14,2.41-2,4.15-2.6c1.73-0.59,3.92-0.89,6.56-0.89h7.15v19.36H255.67z"
        />
        <path
          class="st4"
          d="M462.01,44.58V66.2h9.5c5.3,0,9.27-1.28,11.91-3.83c2.64-2.55,3.96-6.42,3.96-11.61
                  c0-10.64-5.29-15.96-15.87-15.96h-9.5v2.3h2.3h7.15c2.62,0,4.8,0.31,6.53,0.92c1.73,0.61,3.11,1.51,4.15,2.68s1.76,2.61,2.18,4.29
                  c0.42,1.68,0.63,3.61,0.63,5.77c0,2.07-0.2,3.91-0.62,5.54c-0.41,1.63-1.13,3.01-2.17,4.15s-2.41,2-4.15,2.6
                  c-1.73,0.59-3.92,0.89-6.56,0.89h-7.15V44.58H462.01z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'DevmoodIconLogoTextHorizontal',
  props: {
    width: { type: Number, default: 200 },
  },
};
</script>
