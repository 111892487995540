<template>
  <section class="introduction">
    <div class="container align-center justify-center">
      <div class="content">
        <h1>
          <span class="title is-2">{{ traductions.title }}</span>
          <br>
          <span class="subtitle is-3 has-text-muted">{{ traductions.subtitle }}</span>
        </h1>

        <br>

        <div class="buttons">
          <Linkedin />
          <Cv />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Intro',
  props: {
    language: { type: String, required: true },
    traductions: { type: Object, required: true },
  },
};
</script>
