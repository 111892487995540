var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isLink)?_c('a',{class:['button', {
    'is-primary': _vm.type === 'primary',
    'is-secondary': _vm.type === 'secondary',
    'is-ghost': _vm.type === 'ghost',

  }],attrs:{"href":_vm.url},on:{"click":function($event){return _vm.$emit('click')}}},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_c('button',{class:['button', {
    'is-primary': _vm.type === 'primary',
    'is-secondary': _vm.type === 'secondary',
    'is-ghost': _vm.type === 'ghost',
  }],on:{"click":function($event){return _vm.$emit('click')}}},[_vm._v(" "+_vm._s(_vm.label)+" ")])
}
var staticRenderFns = []

export { render, staticRenderFns }