export default {
  computed: {
    languages() {
      return {
        fr: {
          name: 'Français', flag: '🇫🇷', key: 'fr',
        },
        en: {
          name: 'English', flag: '🇬🇧', key: 'en',
        },
      };
    },
  },
};
