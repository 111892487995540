<template>
  <b-button
    v-if="icon"
    :label="content"
    :type="type"
    icon-pack="fa"
    :icon-left="positionIcon === 'left' ? icon : ''"
    :icon-right="positionIcon === 'right' ? icon : ''"
    tag="a"
    href="https://www.linkedin.com/in/nesseirm/"
  />
</template>

<script>
/* eslint-disable-next-line */
import { mapGetters } from 'vuex';
import TheHomeContentTraduction from '../../$mixins/TheHomeContentTraduction.mixins';

export default {
  name: 'Linkedin',
  mixins: [TheHomeContentTraduction],
  props: {
    label: { type: String, default: '' },
    type: { type: String, default: 'is-primary is-light' },
    icon: { type: String, default: 'linkedin' },
    loading: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    positionIcon: { type: String, default: 'left' },
  },
  computed: {
    ...mapGetters({
      lang: 'TheHome/getterCurrentLanguage',
    }),
    content() {
      if (this.label) return this.label;
      return this.buttons[this.lang].linkedin;
    },
  },
};
</script>
