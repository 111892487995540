const includables = [
  'a',
  'b',
  'c',
  'd',
  'e',
  'f',
  'g',
  'h',
  'i',
  'j',
  'k',
  'l',
  'm',
  'n',
  'o',
  'p',
  'q',
  'r',
  's',
  't',
  'u',
  'v',
  'w',
  'x',
  'y',
  'z',
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '_',
  '-',
  '.',
];

export default {
  methods: {
    convertListOfKeywordsIntoArrayOfKeywords(listOfKeywords) {
      return listOfKeywords
        .split('\n')
        .filter((s) => includables.includes(s[0]));
    },
  },
};
