<template>
  <main id="app">
    <router-view v-if="authorizedAccess" />
  </main>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      refusedAccess: {
        title: 'Connexion required',
      },
    };
  },
  computed: {
    authorizedAccess() {
      const { meta } = this.$route;
      // console.log('🚀 ~ file: App.vue ~ line 52 ~ allowAccessTo ~ this.$route', this.$route);
      if (meta && meta.requireAuth) {
        if (!this.currentUser) return false;
        if (!this.isAllCustomFetched) return false;
        if (meta.requiredRights) {
          const { rights } = this.currentUser;
          const matchedRights = Object.keys(rights)
          // eslint-disable-next-line max-len
            .reduce((acc, right) => (meta.requiredRights.includes(right) ? [...acc, right] : acc), []);

          const willAllow = matchedRights.length === meta.requiredRights.length;
          if (!willAllow) {
            this.updateRefuseAccess({
              title: 'Rights Required',
              reason: [
                `You don't have required right(s) to access this page. (${this.$route.name})`,
                `${meta.requiredRights.join(', ')}`,
                'Contact administrator.',
              ],
            });
          }
          return willAllow;
        }
      }
      return true;
    },
  },
  mounted() {
    document.title = 'Devmood';
  },
};
</script>

<style lang="scss">
@import '@/Morpheus/scss/index';
html, body {
  height: 100vh;
  max-height: 100vh;
  overflow: auto;
}
#app {
  overflow-y: auto !important;
  // background-color: var(--solitude);
}
</style>
